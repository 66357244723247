export function isLoggedIn() {
  const correctPassword = 'YnZzaWdAMjAyNA==';

  let value = localStorage.getItem('password');
  if (!value || btoa(value) !== correctPassword) {
    value = window.prompt('Enter the password:');
    if (value) {
      localStorage.setItem('password', value);
    }
  }

  return value && btoa(value) === correctPassword;
}
